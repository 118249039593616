import Typography from '@mui/material/Typography';

import Exclamation from '../../component/icons/Exclamation';
import useBreakPoints from '../../hooks/UseBreakPoints';

const SuccessErrorText = ({ message, isError }:{message:string, isError:boolean}) => {
  const { isIpad, isXSmallMobile } = useBreakPoints();
  const fontWeight = isError ? 600 : 400;

  return (
    <Typography
      id={isError ? 'pop-postal-not-delivered' : ''}
      sx={{
        font: `normal normal ${fontWeight} 14px/22px Nunito`,
        letterSpacing: '0px',
        color: isError ? '#C80328' : '#363636',
        mt: '10px',
        display: 'flex',
        height: '19px',
        alignItems: 'center',
        ...(isIpad ? { mt: '10px' } : {}),
        ...(isXSmallMobile ? {
          font: `normal normal ${fontWeight} 13px/22px Nunito`,
        } : {}),
      }}
    >
      {
      isError && <Exclamation />
      }
      <span style={{ marginLeft: isError ? '6px' : 0 }}>{message}</span>
    </Typography>
  );
};

export default SuccessErrorText;
