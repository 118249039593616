import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';

interface ArrowProps extends BoxProps {
  boundingRect: Record<string, any>;
  isInverted: boolean;
}

const Arrow = React.forwardRef(({ boundingRect, isInverted, ...props }:ArrowProps, ref) => {
  const transform = React.useMemo(() => {
    if (boundingRect?.left >= 0) {
      return {
        top: isInverted ? `${parseInt(boundingRect.top, 10) - 9}px`
          : `${parseInt(boundingRect.top, 10) + parseInt(boundingRect.height, 10) + 0}px`,
        left: `${parseInt(boundingRect.width, 10) / 2 + parseInt(boundingRect.left, 10) - 12}px !important`,
      };
    }
    return {};
  }, [boundingRect]);

  return (
    <Box
      ref={ref}
      sx={{
        zIndex: 9999999,
        position: 'absolute',
        fontSize: 7,
        ...transform,
        width: '3em',
        height: '3em',
        '&::before': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          ...(isInverted ? { borderTop: '25px solid #fff' } : { borderBottom: '25px solid #fff' }),

        },
      }}
      {...props}
    />
  );
});

export default Arrow;
