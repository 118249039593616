/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { EmailFormFields } from 'react-mailchimp-subscribe';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from '../../component/core/Button';
import TextField from '../../component/core/TextField';
import Exclamation from '../../component/icons/Exclamation';
import useBreakPoints from '../../hooks/UseBreakPoints';

interface MailChimpFormProps {
    status: string | null;
    message: string | Error | null;
    subscribe:(data:EmailFormFields)=>void;
}

const ErrorMessage = ({ message }: {message: string | Error | null}) => (
  <Box
    sx={{
      pt: '16px',
    }}
  >
    {typeof message === 'string' && (
      message?.includes('@')
          || message?.includes('Please enter a value')) ? (
            <Typography
              display="flex"
              alignItems="center"
              sx={{
                font: 'normal normal 600 14px/19px Nunito',
                letterSpacing: '0px',
                color: '#C80328',
              }}
            >
              <Exclamation />
                &nbsp;&nbsp;Please enter a valid email address
            </Typography>
      ) : (
        <div
                // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: message || '' as any,
          }}
        />
      )}
  </Box>
);

const SuccessMessage = () => (
  <Box
    sx={{
      pt: '16px',
      font: 'normal normal normal 14px/22px Nunito',
      letterSpacing: '0px',
      color: '#363636',
    }}
  >
    Thanks for signing up! We have sent you an email with a $10 off coupon
    code.
  </Box>
);

const MailChimpForm = ({
  status, message, subscribe,
}:MailChimpFormProps) => {
  const { isIpad } = useBreakPoints();
  const [email, setEmail] = React.useState('');

  const onSubmit = () => {
    subscribe({ EMAIL: email });
  };

  return (
    <>
      <Box
        sx={{
          pt: '32px',
          ...(isIpad ? { pt: '24px' } : {}),
        }}
      >
        <TextField
          height="48px"
          placeholder="Enter your email address"
          onChange={(e:any) => setEmail(e.target.value)}
        />
      </Box>
      {status === 'error' && <ErrorMessage message={message} />}
      {status === 'success' && <SuccessMessage />}
      {status !== 'success' && (
        <Box
          sx={{
            pt: '20px',
            ...(isIpad ? { pt: '16px' } : {}),
          }}
        >
          <Button
            disabled={status === 'sending'}
            onClick={onSubmit}
            title="Sign me up for $10 off"
            titleProps={{
              className: status === 'sending' ? 'button-loading-state' : '',
            }}
          />
        </Box>
      )}
    </>
  );
};

export default MailChimpForm;
