export const canAccess = (accessibleLocationPaths:string[]) => {
  const currentLocation = window.location.pathname;
  if (accessibleLocationPaths.includes('*')) {
    return true;
  }

  if (accessibleLocationPaths.findIndex((location) => location === currentLocation) > -1) {
    return true;
  }

  return false;
};

export const removeHtmlFromString = (htmlStr: string) => {
  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  return htmlStr?.replace(htmlRegexG, '') || '';
};

export const urlQueryParams:any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop:any) => searchParams.get(prop),
});

export const removeQueryParams = (removeParamName: string) => {
  const queryArray = window.location.search?.replace('?', '').split('&');
  return `?${queryArray.filter((x) => !x?.toLowerCase().includes(removeParamName.toLowerCase())).join('&')}`;
};

// crossbrowser version
export const getElementAbsoluteCoordinates = (elem:HTMLElement) => {
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    height: box.height,
    width: box.width,
  };
};
