/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import { getElementAbsoluteCoordinates } from '../../utils/helper';

import Arrow from './Arrow';
import PostalCodePopup, { PostalCodePopupProps } from './PostalCodePopup';

interface PostalCodeDesktopProps extends PostalCodePopupProps {
    anchorEl:any;
    arrowRef:any;
    setAnchorEl: (element:any)=>void;
}

const PostalCodeDesktop = React.forwardRef(({
  anchorEl,
  setAnchorEl,
  ...postalCodePopupProps
}:PostalCodeDesktopProps, ref) => {
  const boundingRect = getElementAbsoluteCoordinates(anchorEl);
  const absoluteHeight = window.screen.height + window.scrollY;
  const isInverted = boundingRect.top + boundingRect.height + 500 > absoluteHeight;

  const transform = React.useMemo(() => {
    const response = postalCodePopupProps?.response;
    let height = 200;
    if (response?.isError) {
      height = 228;
    } else if (!response.isError && response?.isSubmitted) {
      height = 328;
    }

    const popupCoordinates = {
      width: 395,
      height,
    };

    return {
      top: isInverted ? `${boundingRect.top - popupCoordinates.height}px !important`
        : `${boundingRect.top + boundingRect.height}px !important`,
      left: `${boundingRect?.left}px !important`,
      ...(document.body.clientWidth <= boundingRect.left + popupCoordinates.width + 10 ? {
        transform: `translateX(${-popupCoordinates.width + boundingRect.width}px)`,
      } : {}),
    };
  }, [postalCodePopupProps?.response]);

  React.useEffect(() => {
    const isBtnAutoComplete = anchorEl?.id?.toLowerCase()?.includes('autocomplete');
    if (isBtnAutoComplete) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    return () => {
      if (document.getElementsByTagName('body')[0].style.overflow === 'hidden') {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <>
      <Backdrop
        open={!!anchorEl}
        onClick={() => setAnchorEl(null)}
        sx={{ zIndex: 999999 }}
      />
      <Box
        sx={{
          zIndex: 9999999,
          position: 'absolute',
          ...transform,
        }}
      >
        <PostalCodePopup
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isInverted={isInverted}
          {...postalCodePopupProps}
        />
      </Box>
      <Arrow
        ref={ref}
        isInverted={isInverted}
        boundingRect={boundingRect}
      />
    </>
  );
});

export default PostalCodeDesktop;
