import { useMediaQuery } from '@mui/material';

const useBreakPoints = () => {
  const isIpad = useMediaQuery('(max-width:840px)');
  const isMobile = useMediaQuery('(max-width:767px)');
  const isDesktop = useMediaQuery('(min-width:841px)');
  const isBigMobile = useMediaQuery('(max-width:456px)');
  const isSmallMobile = useMediaQuery('(max-width:375px)');
  const isXSmallMobile = useMediaQuery('(max-width:343px)');

  return {
    isIpad,
    isMobile,
    isDesktop,
    isBigMobile,
    isSmallMobile,
    isXSmallMobile,
  };
};

export default useBreakPoints;
