export const verifyPostalCode = async (postalCode: string, clientId: string) => {
  const validatePostalCodeResponse = await fetch(
    `https://${window?.location?.hostname}/catalogue/spud_SignUp_Quick_DeliveryDateOption.cfm?pageid=postalcodepopup&opt=1&&frmType=0&zip=${postalCode}&_cf_containerId=SignUp_QuickID&_cf_nodebug=true&_cf_nocache=true&_cf_clientid=${clientId}&_cf_rc=1`,
  );
  const data = await validatePostalCodeResponse.text();
  return data;
};

export const visitorLogin = async (data:URLSearchParams) => {
  const response = await fetch(`https://${window?.location?.hostname}/myindex.cfm?action=vist_login`, {
    method: 'post',
    body: data,
  });
  const loginResponse = await response.text();
  return loginResponse;
};
