import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

import PostalCodePopup, { PostalCodePopupProps } from './PostalCodePopup';

interface PostalCodeMobileProps extends PostalCodePopupProps {
    open:boolean;
    setAnchorEl: (element:any)=>void;
}

const PostalCodeMobile = ({ open, setAnchorEl, ...props }:PostalCodeMobileProps) => (
  <>
    <Backdrop open={open} onClick={() => setAnchorEl(null)} sx={{ zIndex: 100001 }} />
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box sx={{
        position: 'fixed', bottom: 0, zIndex: 99999999,
      }}
      >
        <PostalCodePopup setAnchorEl={setAnchorEl} {...props} />
      </Box>
    </Slide>
  </>

);

export default PostalCodeMobile;
