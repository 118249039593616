import MuiButton, { ButtonProps } from '@mui/material/Button';
import Typography, { TypographyProps } from '@mui/material/Typography';

import useBreakPoints from '../../hooks/UseBreakPoints';

interface ExtendedBtnProps extends ButtonProps {
    title: string;
    titleProps: TypographyProps;
}

const Button = ({
  title, titleProps, sx, ...props
}:ExtendedBtnProps) => {
  const { sx: titleSx, ...restTitleProps } = titleProps;
  const { isIpad } = useBreakPoints();

  return (
    <MuiButton
      sx={{
        height: '48px',
        background: '#1F644C 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        textTransform: 'none',
        pb: '7px',
        '&:hover': {
          background: `${isIpad ? '#1F644C' : '#0A432F'} 0% 0% no-repeat padding-box`,
          boxShadow: 'none',
        },
        '&:active': {
          background: '#0A432F 0% 0% no-repeat padding-box',
          boxShadow: 'none',
        },
        '&:disabled': {
          background: '#0A432F 0% 0% no-repeat padding-box',
          boxShadow: 'none',
          color: '#FFF',
        },
        boxShadow: 'none',
        ...(sx || {}),
      }}
      variant="contained"
      fullWidth
      disableRipple
      {...props}
    >
      <Typography
        component="span"
        sx={{
          fontFamily: 'nunito',
          fontSize: '16px',
          backgroundColor: 'inherit !important',
          fontWeight: 700,
          letterSpacing: '0px',
          textTransform: 'none',
          ...(titleSx || {}),
        }}
        {...restTitleProps}
      >
        {title}
      </Typography>
    </MuiButton>
  );
};

export default Button;
