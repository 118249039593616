interface XCloseProps {
  style?:any;
  onClick?:(e:any)=>void;
}

const XClose = (props:XCloseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12.002"
    viewBox="0 0 12 12.002"
    {...props}
  >
    <path
      d="M7.1,6.136l4.673-4.673a.778.778,0,0,0-1.1-1.1L6,5.036,1.327.364a.778.778,0,1,0-1.1,1.1L4.9,6.136.228,10.808a.778.778,0,1,0,1.1,1.1L6,7.235l4.673,4.673a.778.778,0,0,0,1.1-1.1Zm0,0"
      transform="translate(0 -0.135)"
      fill="#222"
    />
  </svg>
);

XClose.defaultProps = {
  style: {},
  onClick: () => null,
};

export default XClose;
