import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';

import CouponSlider from './container/CouponSlider';
import PostalCodePopup from './container/PostalCodePopup';
import { canAccess } from './utils/helper';

// import reportWebVitals from './reportWebVitals';

const renderReactComponents = () => {
  const SpudAppWrapper = ({ component }:{component: React.FC}) => {
    const Component = component;

    return (
      <React.StrictMode>
        <CookiesProvider>
          <Component />
        </CookiesProvider>
      </React.StrictMode>
    );
  };

  const root = document.getElementById('root');
  if (root && canAccess(['*'])) {
    const Root = () => (
      <div>
        SPUD React App
      </div>
    );

    ReactDOM.createRoot(root).render(
      <SpudAppWrapper component={Root} />,
    );
  }

  const couponSliderElement = document.getElementById('coupon-slider');
  if (couponSliderElement && canAccess(['*'])) {
    ReactDOM.createRoot(couponSliderElement).render(
      <SpudAppWrapper component={CouponSlider} />,
    );
  }

  const postalCodePopupElement = document.getElementById('postalcode-popup');
  if (postalCodePopupElement && canAccess(['*'])) {
    ReactDOM.createRoot(postalCodePopupElement).render(
      <SpudAppWrapper component={PostalCodePopup} />,
    );
  }
};

(window as any).$?.(document).ready(() => {
  console.log('Rendering React Components');
  renderReactComponents();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
