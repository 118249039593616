import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import Button from '../../component/core/Button';
import TextField from '../../component/core/TextField';
import LocationPin from '../../component/icons/LocationPin';
import useBreakPoints from '../../hooks/UseBreakPoints';

import { ResponseProps } from '.';

const useStyles = () => {
  const {
    isSmallMobile, isDesktop, isIpad, isXSmallMobile,
  } = useBreakPoints();
  return {
    enterPostalCodeText: {
      color: '#363636',
      textAlign: 'center',
      letterSpacing: '0px',
      font: 'normal normal bold 18px/26px Paralucent',
      fontWeight: 600,
      height: '22px',
      ...(isIpad ? {
        font: 'normal normal bold 16px/24px Paralucent',
        height: '19px',
      } : {}),
    },
    pricesAndProductText: {
      mt: '10px',
      color: '#363636',
      letterSpacing: '0px',
      height: '19px',
      font: isSmallMobile ? `normal normal normal ${isXSmallMobile ? 12 : 13}px/22px Nunito` : 'normal normal normal 14px/22px Nunito',
    },
    postalCodeForm: {
      display: 'flex',
      justifyContext: 'center',
      gap: isXSmallMobile ? '6px' : '12px',
      ...(isIpad ? {
        mt: '20px',
      } : { mt: '24px' }),
    },
    postalCodeInputWrapper: {
      minWidth: '165px',
      ...(isDesktop ? { minWidth: '186px' } : {}),
      ...(isSmallMobile ? { minWidth: '120px' } : {}),
    },
    postalCodeInput: {
      pl: isXSmallMobile ? '12px' : '10px',
      '& fieldset': {
        border: '1px solid #B8BBBC',
      },
      '& input': {
        height: '38px',
        padding: '0px',
        border: '0px !important',
        borderRadius: '4px',
        pl: '0px !important',
        color: '#363636',
        font: 'normal normal normal 14px/22px Nunito',
        '&::placeholder': {
          color: '#57595A',
          opacity: 1,
          fontSize: '14px',
          ...(isIpad ? { pb: '3px', bottom: '7.5px' } : { pb: '2px', bottom: '8.1px' }),
        },
        '&::-webkit-input-placeholder': {
          color: '#57595A',
          opacity: 1,
          fontSize: '14px',
          ...(isIpad ? { pb: '3px', bottom: '7.5px' } : { pb: '2px', bottom: '8.1px' }),
        },
        '&::-ms-input-placeholder': {
          color: '#57595A',
          opacity: 1,
          fontSize: '14px',
          ...(isIpad ? { pb: '3px', bottom: '7.5px' } : { pb: '2px', bottom: '8.1px' }),
        },
      },
    },
    checkPostalCodeBtn: {
      height: '38px',
      maxWidth: '30%',
      minWidth: '150px',
      ...(isSmallMobile ? { width: '150px' } : {}),
    },
  };
};

export interface PostalCodeFormProps {
    response:ResponseProps;
    pinCode: string;
    setPinCode: (pinCode:string) => void;
    validatePostalCode: (postalCode:string) => Promise<void>;
}

const PostalCodeForm = ({
  validatePostalCode, pinCode, setPinCode, response,
}:PostalCodeFormProps) => {
  const { isXSmallMobile } = useBreakPoints();
  const styles = useStyles();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography sx={styles.enterPostalCodeText}>
        Enter your delivery postal code
      </Typography>
      <Typography sx={styles.pricesAndProductText}>
        Prices and product availability may vary upon location
      </Typography>
      <Box sx={styles.postalCodeForm}>
        <TextField
          name="postalcode1"
          height="38px"
          placeholder={isXSmallMobile ? 'Enter postal code' : 'Enter your postal code'}
          autoComplete="off"
          sx={styles.postalCodeInputWrapper}
          value={pinCode}
          onChange={(e) => setPinCode(e?.target?.value)}
          id="postalcode1"
          variant="outlined"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              validatePostalCode(pinCode);
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          InputProps={{
            autoComplete: 'off',
            sx: styles.postalCodeInput,
            ...(
              isXSmallMobile ? {} : {
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationPin />
                  </InputAdornment>
                ),
              }
            ),

          }}
        />
        <Button
          variant="contained"
          sx={styles.checkPostalCodeBtn}
          fullWidth
          id="pop-postal-btn"
          disabled={response?.loading}
          title={response?.loading ? '' : 'Check postal code'}
          titleProps={{
            sx: { fontSize: '14px' },
            className: response?.loading ? 'button-loading-state' : '',
          }}
          onTouchEnd={(e:any) => {
            e.target?.blur?.();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              validatePostalCode(pinCode);
              e.stopPropagation();
              e.preventDefault();
            }
          }}
          onClick={() => {
            validatePostalCode(pinCode);
          }}
        />
      </Box>

    </Box>
  );
};

export default PostalCodeForm;
