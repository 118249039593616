/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useCookies } from 'react-cookie';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import XClose from '../../component/icons/XClose';
import useBreakPoints from '../../hooks/UseBreakPoints';

import MailChimpForm from './MailChimpForm';

const isTestMode = (window as any).isTestMode || false;

const SHOW_POPUP_SESSION_MAX_AGE = 33 * 24 * 60 * 60; // 30 days

const CouponSlider = () => {
  const showPopupOncePerSessionCookieName = 'HAS_SHOWN_DISCOUNT_POPUP';
  const { isIpad, isMobile } = useBreakPoints();
  const [showCouponCode, setShowCouponCodePopup] = React.useState(false);
  const [cookies, setCookie] = useCookies([
    'SPUD_BUILDER_CUSTOMER_ATTRIBUTES',
    showPopupOncePerSessionCookieName,
  ]);

  const mailChimpUrl = React.useMemo(() => {
    // form urls based on locations
    // Edm: https://spud.us4.list-manage.com/subscribe/post?u=9717f1793550b8bf93eed1580&amp;id=880a4f1919&amp;f_id=000cc2e1f0
    // Cal: https://spud.us4.list-manage.com/subscribe/post?u=9717f1793550b8bf93eed1580&amp;id=53e24b3a78&amp;f_id=0008c2e1f0
    // Van: https://spud.us4.list-manage.com/subscribe/post?u=9717f1793550b8bf93eed1580&amp;id=52c006f7be&amp;f_id=001cc2e1f0

    const location = cookies?.SPUD_BUILDER_CUSTOMER_ATTRIBUTES?.location;
    const baseUrl = 'https://spud.us4.list-manage.com/subscribe/post?u=9717f1793550b8bf93eed1580';
    let id;
    let fId;

    switch (location) {
      case 'VAN':
        id = '52c006f7be';
        fId = '001cc2e1f0';
        break;
      case 'CAL':
        id = '53e24b3a78';
        fId = '0008c2e1f0';
        break;
      case 'EDM':
        id = '880a4f1919';
        fId = '000cc2e1f0';
        break;
      default:
        // return null;
        id = '52c006f7be';
        fId = '001cc2e1f0';
        break;
    }

    return `${baseUrl}&amp;id=${id}&amp;f_id=${fId}`;
  }, [cookies]);

  React.useEffect(() => {
    const timeout = setTimeout(
      () => {
        if (
          isTestMode
          || (!cookies?.SPUD_BUILDER_CUSTOMER_ATTRIBUTES?.isSignedIn
            && !cookies[showPopupOncePerSessionCookieName])
        ) {
          setCookie(showPopupOncePerSessionCookieName, 'yes', {
            maxAge: SHOW_POPUP_SESSION_MAX_AGE,
          });
          setShowCouponCodePopup(true);
        } else if (cookies?.SPUD_BUILDER_CUSTOMER_ATTRIBUTES?.isSignedIn) {
          setCookie(showPopupOncePerSessionCookieName, 'yes', {
            maxAge: SHOW_POPUP_SESSION_MAX_AGE,
          });
        }

        return () => {
          clearTimeout(timeout);
        };
      },
      isTestMode ? 3000 : 25000,
    );
  }, []);

  const sliderCss = React.useMemo(() => ({
    background: '#B0D350 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #00000029',
    paddingTop: '0px',
    fontWeight: 100,
    pt: '24px',
    ...{
      px: '24px',
      pb: '24px',
      borderRadius: '10px',
      minHeight: '299px',
    },
    position: 'fixed',
    zIndex: '100',
    ...(isIpad
      ? {
        transition: 'all 2s',
        webkitTransition: 'all 2s',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        minHeight: '266px',
        bottom: '-100%',
        borderRadius: '0px',
        width: '100%',
        px: '24px',
        pb: '32px',
        '&.coupon-slider': {
          transform: 'translate3d(0, 0, 0)',
          bottom: '0px',
          animationTimingFunction: '2s ease-in',
        },
        ...(isMobile
          ? {
            px: '16px',
            pb: '32px',
          }
          : {}),
      }
      : {
        transition: 'all 1s',
        webkitTransition: 'all 1s',
        right: '-468px',
        width: '468px',
        bottom: '20px',
        '&.coupon-slider': {
          transform: 'translate3d(-488px, 0, 0)',
          animationTimingFunction: '1s ease-in',
        },
      }),
  }), [isIpad, isMobile]);

  // dont show popup if location is not defined
  // if (!mailChimpUrl) return null;

  return (
    <Box
      className={showCouponCode ? 'coupon-slider' : ''}
      sx={sliderCss}
    >
      <Box
        onClick={() => setShowCouponCodePopup(false)}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          color: '#5C5F62',
          ...(isIpad
            ? {
              top: '12px',
              right: '12px',
            }
            : { top: '16px', right: '16px' }),
        }}
      >
        <XClose />
      </Box>
      <Typography
        sx={{
          pt: '16px',
          textAlign: 'center',
          font: 'normal normal bold 24px/34px Paralucent',
          fontWeight: 600,
          letterSpacing: '0px',
          color: '#363636',
          ...(isIpad
            ? {
              font: 'normal normal bold 20px/30px Paralucent',
            }
            : {}),
        }}
      >
        Get $10 off your first order!
      </Typography>
      <Typography
        sx={{
          pt: '12px',
          textAlign: 'center',
          font: 'normal normal normal 16px/24px Nunito',
          letterSpacing: '0px',
          color: '#363636',
          px: '60px',
          ...(isIpad
            ? {
              px: '0px',
            }
            : {}),
        }}
      >
        Plus, stay up to date on special offers and new product recommendations.
      </Typography>
      {mailChimpUrl && (
        <MailchimpSubscribe
          url={mailChimpUrl}
          render={({ subscribe, status, message }) => (
            <MailChimpForm
              status={status}
              message={message}
              subscribe={subscribe}
            />
          )}
        />
      )}
    </Box>
  );
};

export default CouponSlider;
