const Exclamation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g transform="translate(-251 -368.5)">
      <circle
        cx="8"
        cy="8"
        r="8"
        transform="translate(251 368.5)"
        fill="#ce202f"
      />
      <path
        d="M3.076,3H.706V5.968l.067.688.2,2.091H2.792l.284-2.779Zm.366,8.621q0-1.261-1.557-1.261Q.3,10.36.3,11.621T1.884,12.9Q3.442,12.9,3.442,11.621Z"
        transform="translate(257.1 368.6)"
        fill="#fcf9f9"
      />
    </g>
  </svg>
);

export default Exclamation;
