const ChevronDownIcon = (props: {style?:Record<string, any>}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11.323" height="7.071" viewBox="0 0 11.323 7.071" {...props}>
    <g id="chevron" transform="translate(11.668 1.415) rotate(135)">
      <path id="Rectangle_1782" data-name="Rectangle 1782" d="M.5,0h7A.5.5,0,0,1,8,.5V2A0,0,0,0,1,8,2H.5A.5.5,0,0,1,0,1.5V.5A.5.5,0,0,1,.5,0Z" transform="translate(0.243 0.244)" fill="#000" />
      <path id="Rectangle_1783" data-name="Rectangle 1783" d="M.5,0H8A0,0,0,0,1,8,0V1.5a.5.5,0,0,1-.5.5H.5A.5.5,0,0,1,0,1.5V.5A.5.5,0,0,1,.5,0Z" transform="translate(6.25 8.251) rotate(-90)" fill="#000" />
    </g>
  </svg>
);

ChevronDownIcon.defaultProps = {
  style: {},
};

export default ChevronDownIcon;
