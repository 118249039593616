import React from 'react';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Button from '../../component/core/Button';
import TextField from '../../component/core/TextField';
import Checkmark from '../../component/icons/Checkmark';
import ChevronDownIcon from '../../component/icons/ChevronDownIcon';
import XClose from '../../component/icons/XClose';
import useBreakPoints from '../../hooks/UseBreakPoints';

import PostalCodeForm, { PostalCodeFormProps } from './PostalCodeForm';
import SuccessErrorText from './SuccessErrorText';

const useStyles = (isInverted: boolean) => {
  const {
    isIpad, isBigMobile, isXSmallMobile, isDesktop, isSmallMobile,
  } = useBreakPoints();

  return {
    paper: {
      pb: '24px',
      borderRadius: '4px',
      position: 'relative',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      ...(isIpad ? {
        pl: '24px',
        pr: '24px',
        pt: '32px',
        width: '100vw',
        boxShadow: '0px 0px 6px #00000029',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      } : {
        pl: '24px',
        pr: '24px',
        pt: '30px',
        ...(isInverted ? { marginBottom: '32px' } : { marginTop: '32px' }),
        minHeight: '168px',
        maxWidth: '396px',
        width: '396px',
      }),
      ...(isBigMobile ? {
        pl: '16px',
        pr: '16px',
        pt: '32px',
        width: '100vw',
      } : {}),
    },
    xClose: {
      cursor: 'pointer',
      position: 'absolute',
      right: '10px',
      top: '11px',
      height: '12px',
      width: '12px',
      ...(isIpad ? { top: '10px' } : {}),
    },
    selectDeliveryDateText: {
      color: '#363636',
      textAlign: 'center',
      letterSpacing: '0px',
      font: 'normal normal bold 18px/26px Paralucent',
      fontWeight: 600,
      ...(isIpad ? { font: 'normal normal bold 16px/24px Paralucent', height: '20px' } : {}),
    },
    deliveryDropdownWrapper: {
      display: 'flex',
      justifyContext: 'center',
      gap: isXSmallMobile ? '6px' : '12px',
      mt: '16px',
    },
    deliveryDatePicker: {
      minWidth: '165px',
      ...(isDesktop ? { minWidth: '186px' } : {}),
      ...(isSmallMobile ? { minWidth: '120px' } : {}),
    },
    deliveryInput: {
      font: 'normal normal normal 14px/22px Nunito',
      letterSpacing: '0px',
      color: '#363636',
      '& fieldset': {
        border: '1px solid #B8BBBC !important',
      },
      '& .MuiSelect-select': {
        pl: '12px',
      },
      '& svg': {
        top: 'calc(50% - 4px)',
      },
      '& select': {
        height: '38px',
        padding: '0px',
        border: '0px !important',
        borderRadius: '4px',
        pl: '0px !important',
        color: '#363636',
        font: 'normal normal normal 14px/22px Nunito',
        '&::placeholder': {
          color: '#57595A',
          opacity: 1,
          bottom: '8.1px',
          fontSize: '14px',
          ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
        },
        '&::-webkit-input-placeholder': {
          color: '#57595A',
          opacity: 1,
          bottom: '8.1px',
          fontSize: '14px',
          ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
        },
        '&::-ms-input-placeholder': {
          color: '#57595A',
          opacity: 1,
          bottom: '8.1px',
          fontSize: '14px',
          ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
        },
      },
      '& .mui-selected-delivery-checkbox': {
        display: 'none',
      },
    },
    selectMenuWrapper: {
      transition: 'none !important',
      border: '1px solid #B8BBBC',
      boxShadow: 'none',
      '& ul': {
        height: '143px',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
        backgroundColor: '#F8F7F8 !important',
        background: '#F8F7F8 !important',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px !Important',
        background: '#D9D9D9 0% 0% no-repeat padding-box !important',
        backgroundColor: '#D9D9D9 !important',
      },
      ...(isIpad ? { marginTop: '-51px' } : { marginTop: '-148px' }),
    },
    selectMenu: {
      zIndex: 9999999999,
      '& .Mui-selected': {
        backgroundColor: '#F1EFF1 !important',
      },
      '& .MuiMenu-list': {
        pt: 0,
      },
    },
    menuItem: {
      minHeight: '38px !important',
      py: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      font: 'normal normal normal 14px/22px Nunito',
      letterSpacing: '0px',
      color: '#363636',
      ...(isIpad ? { height: '35px' } : {}),
      '& div': {
        height: '19px',
        textAlign: 'left',
        font: 'normal normal normal 14px/22px Nunito',
        letterSpacing: '0px',
        color: '#363636',
      },
    },
    menuItemSelected: { height: '7px', display: 'flex', alignItems: 'center' },
    continueShoppingBtn: {
      height: '38px',
      px: '12px',
      maxWidth: '30%',
      minWidth: '150px',
      ...(isSmallMobile ? { width: '150px' } : {}),
    },
  };
};

export interface PostalCodePopupProps extends PostalCodeFormProps {
    deliveryDate: string;
    productId:string;
    isInverted?: boolean;
    anchorEl:any;
    onSubmit: () => void;
    setAnchorEl: (element: any) => void;
    selectDeliveryDate:(date:string)=>void;
}

const PostalCodePopup = ({
  response,
  deliveryDate,
  productId,
  isInverted,
  anchorEl,
  setAnchorEl,
  onSubmit, validatePostalCode, selectDeliveryDate, pinCode, setPinCode,
}:PostalCodePopupProps) => {
  const styles = useStyles(!!isInverted);
  const [isSubmitting, setSubmit] = React.useState(false);

  return (
    <Paper sx={styles.paper}>
      <XClose
        onClick={() => setAnchorEl(null)}
        style={styles.xClose}
      />
      <Box
        id="postalcode-popup-form"
        component="form"
        noValidate
        autoComplete="off"
      >
        <PostalCodeForm
          pinCode={pinCode}
          setPinCode={setPinCode}
          response={response}
          validatePostalCode={validatePostalCode}
        />
        {response?.isSubmitted && (
          <SuccessErrorText
            isError={response?.isError}
            message={(response?.isError ? response.message : 'We deliver to your area!') as string}
          />
        )}
        {
            response?.isSubmitted && !response.isError && (
            <Box mt="20px">
              <Typography sx={styles.selectDeliveryDateText}>
                Select a delivery day
              </Typography>
              <Box sx={styles.deliveryDropdownWrapper}>
                <TextField
                  height="38px"
                  select
                  sx={styles.deliveryDatePicker}
                  focused
                  id="New_DelivDayDate"
                  name="New_DelivDayDate"
                  onChange={(x) => selectDeliveryDate(x?.target?.value)}
                  value={deliveryDate}
                  SelectProps={{
                    IconComponent: ChevronDownIcon,
                    MenuProps: {
                      sx: styles.selectMenu,
                      disableRestoreFocus: true,
                      // disableScrollLock: true,
                      // hideBackdrop: true,
                      disablePortal: !!anchorEl?.id?.toLowerCase()?.includes('autocomplete'),
                      transitionDuration: 400,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      PaperProps: {
                        sx: styles.selectMenuWrapper,
                      },
                    },
                  }}
                  InputProps={{
                    autoComplete: 'off',
                    sx: styles.deliveryInput,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const form:any = document?.getElementById('postalcode-popup-form');
                      form?.submit();
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                >
                  {(response?.message as Record<string, any>[])?.map((item) => (
                    <MenuItem
                      key={item?.value}
                      disableRipple
                      value={item?.value}
                      sx={styles.menuItem}
                      selected={item?.value === deliveryDate}
                    >
                      <Box>{item?.label}</Box>
                      {
                        deliveryDate === item?.value
                        && (
                        <Box className="mui-selected-delivery-checkbox" sx={styles.menuItemSelected}>
                          <Checkmark />
                        </Box>
                        )
                      }

                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  type="submit"
                  value="Submit"
                  variant="contained"
                  sx={styles.continueShoppingBtn}
                  id="pop-postal-start-shopping-btn"
                  fullWidth
                  disabled={isSubmitting}
                  title={isSubmitting ? '' : 'Continue shopping'}
                  titleProps={{
                    sx: { fontSize: '14px' },
                    className: isSubmitting ? 'button-loading-state' : '',
                  }}
                  onClick={(e) => {
                    setSubmit(true);
                    onSubmit();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onTouchEnd={(e:any) => e.target?.blur?.()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                />
              </Box>
            </Box>
            )
        }
      </Box>
    </Paper>
  );
};

PostalCodePopup.defaultProps = {
  isInverted: false,
};

export default PostalCodePopup;
