import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

import useBreakPoints from '../../hooks/UseBreakPoints';

type TextFieldProps = {
  height?: string;
} & MuiTextFieldProps;

const TextField = ({ height, sx, ...textfieldProps }:TextFieldProps) => {
  const { isIpad } = useBreakPoints();

  return (
    <MuiTextField
      fullWidth
      sx={{
        height,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        '& fieldset': {
          border: 0,
        },
        '& input': {
          height,
          padding: '0px',
          border: '1px solid #B8BBBC',
          borderRadius: '4px',
          pl: '16px',
          color: '#363636',
          font: 'normal normal normal 16px/32px Nunito',
          '&::placeholder': {
            color: '#57595A',
            opacity: 1,
            bottom: '13.1px',
            fontSize: '16px',
            ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
          },
          '&::-webkit-input-placeholder': {
            color: '#57595A',
            opacity: 1,
            bottom: '13.1px',
            fontSize: '16px',
            ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
          },
          '&::-ms-input-placeholder': {
            color: '#57595A',
            opacity: 1,
            bottom: '13.1px',
            fontSize: '16px',
            ...(isIpad ? { pb: '3px' } : { pb: '2px' }),
          },
        },
        '& .MuiInputBase-root': {
          height,
        },
        ...(sx || {}),
      }}
      {...textfieldProps}
    />
  );
};

TextField.defaultProps = {
  height: '48px',
};

export default TextField;
