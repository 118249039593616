import {
  Backdrop, Box, CircularProgress, Typography,
} from '@mui/material';

import useBreakPoints from '../../hooks/UseBreakPoints';

const ReloadConfirmationModal = ({ city }: {city: string}) => {
  const { isIpad } = useBreakPoints();
  return (
    <>
      <Backdrop
        sx={{ zIndex: 9999999999999 }}
        open
      />
      <Box
        position="fixed"
        sx={{
          backgroundColor: '#fff',
          zIndex: 99999999999999,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          width: '468px',
          maxWidth: '468px',
          height: '218px',
          pt: '40px',
          px: '50px',
          pb: '32px',
          boxShadow: '0px 0px 6px #00000029',
          borderRadius: '10px',
          ...(isIpad ? {
            width: 'calc(100% - 32px)',
            px: '20px',
            pt: '32px',
            pb: '24px',
            height: '187px',
            top: '47%',
          } : {}),
        }}
      >
        <Typography sx={{
          textAlign: 'center',
          font: 'normal normal bold 24px/29px Paralucent',
          letterSpacing: '0px',
          color: '#363636',
          ...(isIpad ? {
            font: 'normal normal bold 20px/24px Paralucent',
          } : {}),
        }}
        >
          We see you are in
          {' '}
          {city}
        </Typography>
        <Typography sx={{
          pt: '24px',
          textAlign: 'center',
          font: 'normal normal normal 18px/26px Nunito',
          letterSpacing: '0px',
          color: '#363636',
          ...(isIpad ? {
            pt: '20px',
            font: 'normal normal normal 16px/23px Nunito',
          } : {}),
        }}
        >
          We are refreshing the page so that we can show you the correct inventory.
        </Typography>
        <Typography sx={{
          pt: '20px',
          font: 'normal normal normal 14px/22px Nunito',
          textAlign: 'center',
          letterSpacing: '0px',
          color: '#363636',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <span style={{ paddingRight: '8px' }}>
            Loading...
          </span>
          <CircularProgress
            disableShrink
            size="18"
            variant="determinate"
            thickness={8}
            value={85}
            sx={{
              color: '#1F644C',
              height: '18px',
              width: '18px',
              '-webkit-animation': 'circle 1.4s linear infinite',
              animation: 'circle 1.4s linear infinite',
              '@keyframes circle': {
                '0%': {
                  transform: 'rotate(0deg)',

                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default ReloadConfirmationModal;
