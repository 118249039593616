import React from 'react';
import { useCookies } from 'react-cookie';

import Box from '@mui/material/Box';

import useBreakPoints from '../../hooks/UseBreakPoints';
import { verifyPostalCode, visitorLogin } from '../../service/SpudApi';
import { removeQueryParams } from '../../utils/helper';

import PostalCodeDesktop from './Desktop';
import PostalCodeMobile from './Mobile';
import ReloadConfirmationModal from './ReloadConfirmationModal';

const isTestMode = false;

export interface ResponseProps {
    isError: boolean;
    isSubmitted: boolean;
    message: string | {label: string;value:string;}[] | null;
    loading?: boolean;
}

const DEFAULT_API_RESPONSE = {
  isError: false,
  isSubmitted: !!isTestMode,
  message: isTestMode ? [{ label: '1', value: '1' }] : null,
};

const PostalCodePopup = () => {
  const [cookie] = useCookies(['SPUD_BUILDER_CUSTOMER_ATTRIBUTES', '2c.cId']);
  const { isDesktop } = useBreakPoints();
  const [arrowRef, setArrowRef] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [response, setResponse] = React.useState<ResponseProps>({ ...DEFAULT_API_RESPONSE });
  const [productId, setProductId] = React.useState<string>('');
  const [pinCode, setPinCode] = React.useState('');
  const [redirectingModalProps, setRedirectingModalProps] = React.useState<Record<string, any>>({
    open: !!isTestMode,
    city: null,
  });
  const [deliveryDate, selectDeliveryDate] = React.useState<string | null>(null);

  const onSubmit = async (shouldSkipOnClick = false) => {
    const data = new URLSearchParams();
    const formElement:any = document.getElementById('postalcode-popup-form') || undefined;
    const formData:any = new FormData(formElement);
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formData) {
      if (pair && pair.length) {
        data.append(pair[0], pair[1]);
      }
    }
    const loginResponse = await visitorLogin(data);
    if (!shouldSkipOnClick) {
      // eslint-disable-next-line no-eval
      eval((anchorEl as any)?.attributes?.onclick?.textContent || '');
    }

    (window as any).isLoggedIn = 1;
    setAnchorEl(null);

    return loginResponse;
  };

  const processResponse = (verifyPostalCodeResponse:any, postalCode: string) => {
    const isError = !verifyPostalCodeResponse
    || ['Please enter a valid postal code.', 'We don\'t deliver to this area... yet!'].findIndex((x) => verifyPostalCodeResponse.includes(x)) > -1;

    const numberRegex = /\d+/g;
    const charRegex = /[a-z]+/ig;

    if (isError) {
      const shouldUseCustomMessage = postalCode?.match(charRegex)?.length === 3
      && postalCode?.match(numberRegex)?.length === 3;

      setPinCode('');

      return setResponse({
        isError: true,
        isSubmitted: true,
        loading: false,
        message: shouldUseCustomMessage ? `${postalCode} is outside our current delivery area` : 'Please enter a valid postal code.',
      });
    }

    const html = document.createElement('html');
    html.innerHTML = verifyPostalCodeResponse;
    const spanArray = Array.from(html.getElementsByTagName('span'));
    const isItemLocationDifferentThenActual = spanArray.find((ele) => ele.id === 'New_Location');
    if (isItemLocationDifferentThenActual) {
      setRedirectingModalProps({ open: true, city: isItemLocationDifferentThenActual.innerText });
      setTimeout(() => {
        const search = removeQueryParams('lid');
        onSubmit(true).then(() => {
          window.location.replace(`https://${window.location.hostname}${window.location.pathname}${search}`);
        });
      }, 4000);
    }

    const optionTags = Array.from(html.getElementsByTagName('option'));
    if (optionTags.length > 0) {
      const options = optionTags.map((x) => ({
        label: x.innerText,
        value: x.value,
      }));

      setResponse({
        isError: false,
        isSubmitted: true,
        message: options,
        loading: false,
      });
      selectDeliveryDate(options?.[0]?.value);
    }
    return null;
  };

  const validatePostalCode = async (postalCode = '') => {
    try {
      if (!postalCode) throw new Error('Please enter a valid postal code');

      setResponse({ ...DEFAULT_API_RESPONSE, loading: true });

      const verifyPostalCodeResponse = await verifyPostalCode(postalCode, cookie['2c.cId']);

      processResponse(verifyPostalCodeResponse, postalCode);
    } catch (e:any) {
      setResponse({
        isError: true,
        isSubmitted: true,
        loading: false,
        message: e.message,
      });
    }
  };

  const handleClick = (event: any) => {
    const target = event?.target?.closest('.add-to-cart-slide'); // Or any other selector.
    if (target) {
      // if logged in or if we enter postal code in postal code section we wont show this popup.
      if ((window as any).isLoggedIn > 0 || document.getElementById('is_logged')) {
        return;
      }

      const triggeringProductId = target.attributes?.onclick?.textContent?.split(',')?.[2]?.replace(/'/g, '');
      setProductId(triggeringProductId);
      setAnchorEl(event.target);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  React.useEffect(() => {
    if (!anchorEl) {
      setResponse({ ...DEFAULT_API_RESPONSE });
      selectDeliveryDate(null);
      setProductId('');
      setPinCode('');
    }
  }, [anchorEl]);

  if (!anchorEl) return null;

  const commonProps = {
    pinCode,
    response,
    productId,
    deliveryDate: deliveryDate || '',
    onSubmit,
    setPinCode,
    setAnchorEl,
    validatePostalCode,
    selectDeliveryDate,
  };

  return (
    <Box onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    >
      {
        redirectingModalProps?.open
        && (
          <ReloadConfirmationModal
            city={redirectingModalProps.city}
          />
        )
      }
      {isDesktop
        ? (
          <PostalCodeDesktop
            ref={setArrowRef}
            arrowRef={arrowRef}
            anchorEl={anchorEl}
            {...commonProps}
          />
        )
        : (
          <PostalCodeMobile
            open={!!anchorEl}
            anchorEl={anchorEl}
            {...commonProps}
          />
        )}
    </Box>
  );
};

export default PostalCodePopup;
